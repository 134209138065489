import firebase from "../config/firebase";

class QuotesFirebaseDataService {
  db: any;

  constructor(key: string) {
    this.db = firebase.ref(`/quotes-${key}`);
  }
  getAll() {
    const userId = localStorage.getItem("user_id");

    if (!userId) {
      // Se não houver user_id, retornamos a referência filtrada que sempre resultará vazia
      return [];
    }

    // Se houver user_id, retornamos a referência filtrada por user_id
    return this.db;
  }

  create(data: any) {
    return this.db.push(data);
  }

  update(key: string, value: Object) {
    return this.db.child(key).update(value);
  }

  delete(key: string) {
    return this.db.child(key).remove();
  }

  deleteAll() {
    return this.db.remove();
  }
}

export default QuotesFirebaseDataService;
