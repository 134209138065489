import React, { useEffect, useState } from "react";
import {
  Layout,
  Card,
  Typography,
  Avatar,
  Button,
  Row,
  Col,
  Statistic,
  Table,
  Badge,
} from "antd";
import {
  User,
  Search,
  ShoppingCart,
  ChevronRight,
  LineChart,
  FileText,
  Eye,
} from "lucide-react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import Sidebar from "../Sidebar";
import HeaderPage from "../HeaderPage";
import dayjs from "dayjs";

const { Title, Text } = Typography;

interface ResponseItem {
  id: string;
  shipper: string;
  consignee: string;
  selectPortoEmbarque: string;
  selectPortoDescarga: string;
  selectMercadoria: string;
  quantidade_containers: string;
  tipo_container: string;
  data_embarque: string;
  armador: string;
  embarcador_email: string;
  embarcador_nome: string;
}

const Dashboard = () => {
  const email = localStorage.getItem("user_email");
  const [qtSearch, setqtSearch] = useState(0);
  const [qtBooking, setqtBooking] = useState(0);
  const [nameUser, setNameUser] = useState("Visitante");
  const [response, setResponse] = useState<ResponseItem[]>([]);

  useEffect(() => {
    api.post("/user/find_user", { email }).then((resp) => {
      setNameUser(resp.data.user.name);
      setqtSearch(resp.data.user.search ?? 0);
    });

    api.post("/booking/list_booking", { email }).then((resp) => {
      const totalBooking = resp.data.list.length;
      setqtBooking(totalBooking);
    });

    const fetchData = async () => {
      try {
        await api.get(`quotations/mynacs/${email}`).then((res) => {
          setResponse(res.data.list);
        });
      } catch (error) {
        console.error("Error fetching quotations data:", error);
      }
    };

    fetchData();
  }, [email]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "done":
        return "bg-green-200 text-green-800";
      case "pending":
        return "bg-yellow-200 text-yellow-800";
      case "saved":
        return "bg-blue-200 text-blue-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  const columns = [
    {
      title: "#ID",
      dataIndex: "_id",
      key: "_id",
      render: (id) => id.substring(id.length - 6),
    },
    {
      title: "Porto Origem",
      dataIndex: "selectPortoEmbarque",
      key: "selectPortoEmbarque",
      render: (porto) => porto.split(" - ")[0],
    },
    {
      title: "Porto Destino",
      dataIndex: "selectPortoDescarga",
      key: "selectPortoDescarga",
      render: (porto) => porto.split(" - ")[0],
    },
    {
      title: "Data Embarque",
      dataIndex: "data_embarque",
      key: "data_embarque",
      render: (date) => dayjs(date, "DD-MM-YYYY").format("DD/MM/YYYY"),
    },
    {
      title: "Qtd Containers",
      dataIndex: "qtdContainers",
      key: "qtdContainers",
    },
    {
      title: "Tipo Container",
      dataIndex: "tipoContainer",
      key: "tipoContainer",
    },
    {
      title: "Moeda",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "Valor",
      dataIndex: "targetOceanFreight",
      key: "targetOceanFreight",
    },
    {
      title: "Qtd Armadors",
      dataIndex: "totalRegistros",
      key: "totalRegistros",
    },
    {
      title: "Qtd Cotados",
      dataIndex: "totalCotados",
      key: "totalCotados",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Badge className={`${getStatusColor(status)} px-2 py-1`}>
          {status || "N/A"}
        </Badge>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Link to={`/list/nac/${record._id}`}>
          <Button
            type={record.status !== "Done" ? "primary" : "default"}
            icon={<Eye />}
          >
            Ver
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="flex-dashboard">
      <Sidebar elementoAtivo="dashboard" />
      <main className="w-full min-h-screen bg-gray-50">
        <HeaderPage nomeOpcao="Dashboard" />
        <div className="p-6">
          <Row gutter={[24, 24]}>
            {/* Profile Card */}
            <Col xs={24} lg={8}>
              <Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
                <div className="bg-blue-50 p-6">
                  <Title level={5} className="mb-0">
                    Bem Vindo!
                  </Title>
                </div>
                <div className="p-6 flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="bg-blue-500 p-4 rounded-full">
                      <User className="h-8 w-8 text-white" />
                    </div>
                    <div>
                      <Title level={4} className="mb-1">
                        {nameUser}
                      </Title>
                      <Text type="secondary">{email}</Text>
                    </div>
                  </div>
                  <Link to="/conta">
                    <Button type="primary">Minha Conta</Button>
                  </Link>
                </div>
              </Card>
            </Col>

            {/* Stats Cards */}
            <Col xs={24} lg={8}>
              <Card className="shadow-sm h-full">
                <div className="flex items-start gap-4">
                  <Search className="h-6 w-6 text-blue-500" />
                  <div className="flex-1">
                    <Title level={3} className="mb-1">
                      {qtSearch}
                    </Title>
                    <Text className="text-gray-500 block mb-4">
                      Cotações Solicitadas
                    </Text>
                    <Link to="/cotacoes">
                      <Button type="link" className="p-0 flex items-center">
                        Solicitar Cotação{" "}
                        <ChevronRight className="h-4 w-4 ml-2" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>

            <Col xs={24} lg={8}>
              <Card className="shadow-sm h-full">
                <div className="flex items-start gap-4">
                  <ShoppingCart className="h-6 w-6 text-blue-500" />
                  <div className="flex-1">
                    <Title level={3} className="mb-1">
                      {qtBooking}
                    </Title>
                    <Text className="text-gray-500 block mb-4">
                      Bookings Solicitados
                    </Text>
                    <Link to="/bookings">
                      <Button type="link" className="p-0 flex items-center">
                        Ver Detalhes <ChevronRight className="h-4 w-4 ml-2" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>

            {/* Quick Quote Card */}
            <Col xs={24}>
              <Card
                className="shadow-sm text-center"
                title={
                  <div className="flex items-center justify-center gap-2">
                    <LineChart className="h-5 w-5" />
                    <span>Cotação Instantânea</span>
                  </div>
                }
              >
                <Text className="mb-6 block">
                  Solicite uma cotação rápida e receba as melhores ofertas de
                  frete em instantes.
                </Text>
                <Link to="/cotacoes">
                  <Button
                    type="primary"
                    size="large"
                    className="flex items-center mx-auto"
                  >
                    Cotação Rápida
                    <ChevronRight className="h-4 w-4 ml-2" />
                  </Button>
                </Link>
              </Card>
            </Col>

            {/* NAC Quotations Table */}
            {email !== "ffleck@gmail.com" &&
              email !== "alvaro@karavel.com.br" && (
                <Col xs={24}>
                  <Card
                    className="shadow-sm"
                    title={
                      <div className="flex items-center gap-2">
                        <FileText className="h-5 w-5" />
                        <span>Cotações NAC</span>
                      </div>
                    }
                  >
                    <Table
                      columns={columns}
                      dataSource={response}
                      rowKey="id"
                      pagination={{ pageSize: 5 }}
                      className="mt-4"
                    />
                  </Card>
                </Col>
              )}
          </Row>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
