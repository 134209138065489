import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderPage from "../HeaderPage";
import Sidebar from "../Sidebar";
import FreightForm from "./components/forms/FreightForm";
import FreightList from "./components/table/FreightList";
import QuotesFirebaseDataService from "../../firebase/service/quotes.firebase.data.service";
import FreightScheduleList from "./components/table/FreightScheduleList";
import moment from "moment";
import ConsolidateBooking from "../Consolida";

const STORAGE_KEY = "quotes_engineering_state";

const QuotesEnginnering = () => {
  const location = useLocation();
  const [quotes, setQuotes] = useState<any[]>([]);
  const [quotesSchedule, setQuotesSchedule] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [currentPortFromName, setCurrentPortFromName] = useState<string>("");
  const [currentPortToName, setCurrentPortToName] = useState<string>("");
  const [lastSearchParams, setLastSearchParams] = useState<any>(null);
  const quotesRef = useRef<any>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const filterByPortCodeAndDate = (
    quotesArray: any[],
    value: any,
    baseDate: string
  ) => {
    const baseDateMoment = moment(baseDate);
    const minDate = moment(baseDateMoment).subtract(5, "days");
    const maxDate = moment(baseDateMoment).add(30, "days");

    return quotesArray
      .filter((item) => {
        const matchesPorts =
          item.from_code === value.port_from_code &&
          item.to_code === value.port_to_code;
        const itemDate = moment(item.etd);
        const isWithinDateRange = itemDate.isBetween(
          minDate,
          maxDate,
          "day",
          "[]"
        );
        return matchesPorts;
      })
      .sort((a, b) => (a.waiting_bot === true ? -1 : 1));
  };

  const handleDataChange = (items: any, value: any, filters?: any) => {
    try {
      const quotesArray: any[] = [];
      items.forEach((item: any) => {
        const data = item.val();
        quotesArray.push({
          ...data,
          port_from_name: value.port_from_name,
          port_to_name: value.port_to_name,
        });
      });

      let filteredData = filterByPortCodeAndDate(
        quotesArray,
        value,
        value.date
      );

      if (filters) {
        const { containerSizeFilter, carrierFilter, priceRange } = filters;
        filteredData = filteredData.filter((item) => {
          const matchesSize =
            containerSizeFilter.length === 0 ||
            containerSizeFilter.includes(item.container_size);
          const matchesCarrier =
            carrierFilter.length === 0 ||
            carrierFilter.includes(item.type?.toLowerCase());
          const price = getPriceValue(item.value);
          const matchesPrice = price >= priceRange[0] && price <= priceRange[1];
          return matchesSize && matchesCarrier && matchesPrice;
        });
      }

      setQuotes(filteredData);
      setLoading(false);

      // Filtra os registros com waiting_bot = true antes de salvar no localStorage
      const filteredForStorage = filteredData.filter(
        (item) => !item.waiting_bot
      );

      // Salva o estado atualizado apenas com registros sem waiting_bot
      const stateToSave = {
        quotes: filteredForStorage,
        currentPortFromName: value.port_from_name,
        currentPortToName: value.port_to_name,
        lastSearchParams: value,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(stateToSave));
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Erro ao processar dados")
      );
      setLoading(false);
    }
  };
  const getPriceValue = (priceString: string | number): number => {
    if (typeof priceString === "number") return priceString;
    const cleanValue =
      priceString?.replace(/USD/g, "").replace(/,/g, "").trim() || "0";
    const numbersOnly = cleanValue.replace(/[^\d.]/g, "");
    return parseFloat(numbersOnly) || 0;
  };

  const onFinish = async (value: any, filters?: any) => {
    setCurrentPortFromName(value.port_from_name);
    setCurrentPortToName(value.port_to_name);
    setLastSearchParams(value);

    if (quotesRef.current) {
      quotesRef.current.off("value");
    }

    try {
      const quotesDataService = new QuotesFirebaseDataService(
        value.date_firebase
      );
      quotesRef.current = quotesDataService.getAll();
      quotesRef.current.on("value", (items) =>
        handleDataChange(items, value, filters)
      );

      setQuotesSchedule(value.data?.nextFreight || []);

      setTimeout(
        () => tableRef.current?.scrollIntoView({ behavior: "smooth" }),
        100
      );
    } catch (error) {
      console.error("Erro na busca:", error);
      setError(error instanceof Error ? error : new Error("Erro na busca"));
    }
  };

  useEffect(() => {
    // Carrega dados salvos ao montar o componente

    const savedState = localStorage.getItem(STORAGE_KEY);
    if (savedState) {
      try {
        const parsed = JSON.parse(savedState);
        setQuotes(parsed.quotes || []);
        setCurrentPortFromName(parsed.currentPortFromName || "");
        setCurrentPortToName(parsed.currentPortToName || "");
        setLastSearchParams(parsed.lastSearchParams || null);
      } catch (error) {
        console.error("Erro ao carregar estado salvo:", error);
      }
    }
  }, [location.state]);

  return (
    <div className="flex-dashboard">
      <Sidebar elementoAtivo="quotesenginner" />
      <main>
        <HeaderPage nomeOpcao="Cotaçao SPOT - BETA" />
        <div className="main-content">
          <div className="main-content-title">
            <div>
              <FreightForm
                onFinish={onFinish}
                initialValues={lastSearchParams}
              />
              <FreightList ref={tableRef} data={quotes} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default QuotesEnginnering;
